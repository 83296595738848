import '../scss/main.scss';
import $ from "jquery";

//INIT ALL FUNCTIONS
bgAnims();
spacerFlex();
onChangeSelect();
autoCompleteSearch();
checkboxFilter();
modal();

function bgAnims() {
    VANTA.FOG({
        el: "#bg--animations",
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        highlightColor: "#dddddd",
        midtoneColor: "#ff0000",
        lowlightColor: "#dddddd",
        baseColor: "#f6f6f6",
        blurFactor: 0.90,
        speed: 2.80,
        zoom: 0.70
    });
}

function spacerFlex() {
    if (window.matchMedia("(min-width: 769px)").matches) {
        if ($('.results__page').length) {
            const container_block = $('.results__collaborators'),
                nb_items_row_3 = 3,
                counter_for = [];

            for (let j = 1; j <= nb_items_row_3; j++) {
                counter_for[j] = container_block.append('<div class="block spacer"></div>')
            }
        }
    }
}

var data = {
    category_service: '',
    category_role: '',
    category_agency: '',
    category_brands: '',
    category_business_expertise: '',
    category_typology_of_clients: ''
}

function autoCompleteSearch() {
    $('#form-multi-filters input.search-autocomplete').keyup(function () {
        let results = $('#results');
        results.html('');
        var nom = $('.search-autocomplete').val();
        $.ajax({
            url: admin_ajax_url.ajaxurl,
            type: "POST",
            data: {
                'action': 'data_fetch',
                'dataType': 'JSON',
                'keyword': nom
            }
        }).done(function (data) {
            if (data && data.length) {
                if ($('.search-autocomplete').val()) {
                    results.html('');
                    results.append(data);
                    $('.no-results').hide();
                    spacerFlex();
                    setTimeout(function () {
                        $('html,body').animate({scrollTop: results.offset().top}, 500);
                    }, 800);
                }
            } else {
                $('.no-results').show();
            }

            //console.log(data);
        }).fail(function (error) {
            console.log(error);
        });
    });
}

function checkboxFilter() {
    let results = $('#results');
    $('#form-multi-filters input[name="referent-p1"]').click(function () {
        results.html('');

        $.ajax({
            url: admin_ajax_url.ajaxurl,
            type: "POST",
            data: {
                'action': 'data_checkbox',
                'data': data,
                'dataType': 'JSON'
            }
        }).done(function (data) {
            if (data && data.length) {
                if ($('#form-multi-filters input[name="referent-p1"]').is(':checked')) {

                    results.append(data);
                    $('.no-results').hide();
                    spacerFlex();
                    setTimeout(function () {
                        $('html,body').animate({scrollTop: results.offset().top}, 500);
                    }, 800);
                }
            } else {
                $('.no-results').show();
            }

            //console.log(data);
        }).fail(function (error) {
            console.log(error);
        });
    });
}

function onChangeSelect() {
    $('#form-multi-filters select').change(function () {
        let value = $(this).val();
        let name = $(this).attr('name');
        let results = $('#results');
        name = name.replaceAll('-', '_');
        data['category_' + name] = value;

        if (value === "all") {
            $('#form-multi-filters select').children('option').removeAttr('isdisabled');
            results.html('');
        } else {
            filterAjax(data);
        }

        return false;
    });
}

function filterAjax(data) {
    let results = $('#results');
    $.ajax({
        url: admin_ajax_url.ajaxurl,
        type: "POST",
        data: {
            'action': 'load_filters_items',
            'data': data,
            'dataType': 'JSON'
        }
    }).done(function (response) {

        if (response && response.length) {
            response = JSON.parse(response);

            if (Object.keys(response.tax.category_service).length) {

                let services = response.tax.category_service,
                    select_services = $('select[name="service"]');
                select_services.children('option').attr('isdisabled', 'true');

                $.each(services, function (index, item) {
                    select_services.children('option[value="all"]').removeAttr('isdisabled');
                    select_services.children('option[value="' + item.slug + '"]').removeAttr('isdisabled');
                });
            }

            if (Object.keys(response.tax.category_role).length) {

                let roles = response.tax.category_role,
                    select_roles = $('select[name="role"]');
                select_roles.children('option').attr('isdisabled', 'true');

                $.each(roles, function (index, item) {
                    select_roles.children('option[value="all"]').removeAttr('isdisabled');
                    select_roles.children('option[value="' + item.slug + '"]').removeAttr('isdisabled');
                });
            }

            if (Object.keys(response.tax.category_agency).length) {

                let agencies = response.tax.category_agency,
                    select_agencies = $('select[name="agency"]');
                select_agencies.children('option').attr('isdisabled', 'true');

                $.each(agencies, function (index, item) {
                    select_agencies.children('option[value="all"]').removeAttr('isdisabled');
                    select_agencies.children('option[value="' + item.slug + '"]').removeAttr('isdisabled');
                });
            }

            if (Object.keys(response.tax.category_brands).length) {

                let brands = response.tax.category_brands,
                    select_brands = $('select[name="brands"]');
                select_brands.children('option').attr('isdisabled', 'true');

                $.each(brands, function (index, item) {
                    select_brands.children('option[value="all"]').removeAttr('isdisabled');
                    select_brands.children('option[value="' + item.slug + '"]').removeAttr('isdisabled');
                });
            }

            if (Object.keys(response.tax.category_business_expertise).length) {

                let business_expertises = response.tax.category_business_expertise,
                    select_category_business_expertise = $('select[name="business-expertise"]');
                select_category_business_expertise.children('option').attr('isdisabled', 'true');

                $.each(business_expertises, function (index, item) {
                    select_category_business_expertise.children('option[value="all"]').removeAttr('isdisabled');
                    select_category_business_expertise.children('option[value="' + item.slug + '"]').removeAttr('isdisabled');
                });
            }

            if (Object.keys(response.tax.category_typology_of_clients).length) {

                let typology_of_clients = response.tax.category_typology_of_clients,
                    select_typology_of_clients = $('select[name="typology-of-clients"]');
                select_typology_of_clients.children('option').attr('isdisabled', 'true');

                $.each(typology_of_clients, function (index, item) {
                    select_typology_of_clients.children('option[value="all"]').removeAttr('isdisabled');
                    select_typology_of_clients.children('option[value="' + item.slug + '"]').removeAttr('isdisabled');
                });
            }
        }

        results.html('');

        if (Object.keys(response.posts).length) {

            let posts = response.posts;

            $.each(posts, function (index, item) {
                results.append(postsHtml(item));
            });

            $('.no-results').hide();
            spacerFlex();
            $('html,body').animate({scrollTop: results.offset().top - 40}, 225);
        } else {
            $('.no-results').show();
        }

        //console.log(response);

    }).fail(function (error) {
        console.log(error);
    });
}

function postsHtml(post) {
    var html = `<div id="collaborator-id-` + post.ajax_post_id + `" class="results__collaborator block">
                <button class="open-modal">
                <div class="col">
                <div class="perso">
                <div class="col">
                <div class="photo">
                <img src="` + post.ajax_post_photo + `"
            width="60"
            height="60"
            alt="` + post.ajax_post_name + `"
            title="` + post.ajax_post_name + `"/>
                </div>
        </div>
            <div class="col">
                <span class="title">` + post.ajax_post_name + `</span>
                <span class="role">` + post.ajax_post_role[0]["name"] + `</span>
            </div>
        </div>
        </div>
            <div class="col">
                Agence : <span class="agency">` + post.ajax_post_agency[0]["name"] + `</span>
            </div>
        </button>
        <aside id="collaborator-id-` + post.ajax_post_id + `" class="modal__collaborator">
 <div class="container small">
  <div class="modal__collaborator---wrapper">
 <button class="close-modal"><svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><g data-name="close"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/><path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"/></g></g></svg></button>
    <div class="modal__collaborator---header">
            <div class="modal__collaborator---header-photo">
                <img src="` + post.ajax_post_photo + `"
                     width="200"
                     height="200"
                     alt="` + post.ajax_post_name + `"
                     title="` + post.ajax_post_name + `"/>
            </div>
            <h1 class="modal__collaborator---header-title">
                ` + post.ajax_post_name + `
            </h1>
        </div>
    <div class="modal__collaborator---content">
        <div class="col">
            <div class="modal__collaborator---block">
                <div class="modal__collaborator---block-role">
                    <div class="label">Fonction :</div>
                    <div class="value">` + post.ajax_post_role[0]["name"] + `</div>
                </div>`;
                console.log(post);
    if (post.ajax_post_business_expertise) {
        if (post.ajax_post_business_expertise) {
            html = html + `<div class="modal__collaborator---block-typology-of-clients">
                    <div class="label">Expertise métier :</div>
                    <div class="value">` + post.ajax_post_business_expertise + `</div>
                </div>`;
        }
    }
    if (post.ajax_post_skills) {
        if (post.ajax_post_skills) {
            html = html + `<div class="modal__collaborator---block-skills">
            <div class="label">Compétences :</div>
            <div class="values">` + post.ajax_post_skills + `</div>
        </div>`;
        }
    }
    html = html + `</div>
            <div class="modal__collaborator---block">
                <div class="modal__collaborator---block-mobile">
                    <div class="label">Téléphone :</div>
                    <div class="value"><a href="tel:` + post.ajax_post_mobile + `">` + post.ajax_post_mobile + `</a></div>
                </div>
                <div class="modal__collaborator---block-email">
                    <div class="label">Email :</div>
                    <div class="value"><a href="mailto:` + post.ajax_post_email + `">` + post.ajax_post_email + `</a></div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="modal__collaborator---block">
                <div class="modal__collaborator---block-service">
                    <div class="label">Service :</div>
                    <div class="value">` + post.ajax_post_service[0]["name"] + `</div>
                </div>
                <div class="modal__collaborator---block-agency">
                    <div class="label">Localisation :</div>
                    <div class="value">` + post.ajax_post_agency[0]["name"] + `</div>
                </div>
                <div class="modal__collaborator---block-responsable">
                    <div class="label">Responsable :</div>
                    <div class="value">` + post.ajax_post_responsable + `</div>
                </div>
            </div>
            <div class="modal__collaborator---block">`;
            if (post.ajax_post_typology_clients) {
                if (post.ajax_post_typology_clients[0]) {
                    html = html + `<div class="modal__collaborator---block-typology-of-clients">
                            <div class="label">Typologie clients :</div>
                            <div class="value">` + post.ajax_post_typology_clients[0]["name"] + `</div>
                        </div>`;
                }
            }
            html = html + `<div class="modal__collaborator---block-referent-p1">
                    <div class="label">Référent P1 :</div>
                    <div class="value">` + post.ajax_post_referent_p1 + `</div>
                </div>
                <div class="modal__collaborator---block-brands">
                    <div class="label">Marques :</div>
                    <div class="values">` + post.ajax_post_brands + `</div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </div>
</aside>
        </div>`;

    return html;
}

function modal() {
    $(document).on('click', '.open-modal', function () {
        $('.modal__collaborator').removeClass('active');
        $('body').addClass('popin-open');
        $(this).parent().find('.modal__collaborator').addClass('active');
        return false;
    });

    $(document).on('click', '.close-modal', function () {
        $('.modal__collaborator').removeClass('active');
        $('body').removeClass('popin-open');
        return false;
    });

    $(document).on("click", function (event) {
        if (!$(event.target).closest(".modal__collaborator > .container").length) {
            $('.modal__collaborator').removeClass('active');
            $('body').removeClass('popin-open');
        }
    });
}